.home_categories.first_set {
    .slick-slider::before {
        opacity: 0;
    }
}

.home_categories {
    .slick-slider {
        position: relative;
    }
    .slick-prev::before {
        content: unset;
    }
    .slick-next::before {
        content: unset;
    }
}

.home_layout_grid {
    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "categories"
            "slideshow"
            "hotAds";
    }
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    // background-color: orange;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "categories  categories categories categories categories categories"
        "slideshow   slideshow slideshow slideshow hotAds hotAds";
    justify-items: stretch;

    // grid-column: 3 / span 2;
    .block-features__item {
        @media (max-width: 992px) {
            grid-column: span 1;
        }

        grid-column: span 3;
    }

    .home_hot_ads_list {
        max-width: 100%;
        margin: 0;
        min-width: 0;
        min-height: 0;
        grid-area: hotAds;

        .block-products-carousel__cell {
            height: 100%;
        }
        .slick-list {
            height: 100%;
            transform-style: preserve-3d;
        }
        .slick-slider {
            height: 100%;
        }
        .slick-track {
            height: 100%;
        }
        .slick-track .slick-slide {
            height: 100%;
        }

        .slick-slide > div {
            height: 100%;
        }

        .slick-slide > div > div {
            height: 100%;
        }

        .slick-slide .block-products-carousel__column {
            height: 100%;
        }

        @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

        .block-slideshow__slide {
            height: 100%;
        }

        .slick-arrow {
            cursor: pointer;
            height: 30px;
            color: #f1861e;
        }

        .slick-arrow::before {
            content: "";
        }

        .slick-prev {
            top: 50%;
            left: 8px;
            z-index: 10;
        }
        .slick-prev::before {
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f053";
            color: #f1861e;
            font-size: 30px;
        }

        .slick-next {
            top: 50%;
            right: 8px;
            z-index: 10;
        }

        .slick-next::before {
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f054";
            color: #f1861e;
            font-size: 30px;
        }
    }

    
    .home_categories {
        max-width: 100%;
        min-width: 0;
        min-height: 0;
        grid-area: categories;
    }

    .home_slideshow {
        max-width: 100%;
        margin: 0;
        min-width: 0;
        min-height: 0;
        grid-area: slideshow;

        .slick-list {
            height: 395px;
        }
    }
    .home_categories_responsive {
        display: grid;
        grid-auto-flow: column;
        overflow-x: auto;
        gap: 1rem;

    }
    .home_page_info_wrapper {
        
    }
    .title {
        font-size: 25px;
        font-weight: 600;
    }
    .content {
        font-size: 16px;
        font-family: cursive;
        line-height: 35px;
    }
    
}
.home_new_ads_list {
    max-width: 100%;
    min-width: 0;
    min-height: 0;
    grid-area: newAds;

    .slick-list {
        height: 100%;
        transform-style: preserve-3d;
    }
    .slick-slider {
        height: 100%;
    }
    .slick-track {
        height: 100%;
    }
    .slick-track .slick-slide {
        height: 100%;
    }

    .slick-slide > div {
        height: 100%;
    }

    .slick-slide > div > div {
        height: 100%;
    }

    // .slick-slide .block-products-carousel__column {
    //     height: 100%;
    // }

    @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

    .block-slideshow__slide {
        height: 100%;
    }

    .slick-arrow {
        cursor: pointer;
        height: 30px;
        color: #f1861e;
    }

    .slick-arrow::before {
        content: unset;
    }

    .slick-prev {
        top: 50%;
        left: -40px;
        z-index: 10;
    }
    .slick-prev::before {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f053";
        color: #f1861e;
        font-size: 30px;
    }

    .slick-next {
        top: 50%;
        right: -40px;
        z-index: 10;
    }

    .slick-next::before {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f054";
        color: #f1861e;
        font-size: 30px;
    }
}

.home_favorite_ads_list {
    max-width: 100%;
    margin: 0;
    min-width: 0;
    min-height: 0;
    grid-area: favoriteAds;

    .slick-list {
        height: 100%;
        transform-style: preserve-3d;
    }
    .slick-slider {
        height: 100%;
    }
    .slick-track {
        height: 100%;
    }
    .slick-track .slick-slide {
        height: 100%;
    }

    .slick-slide > div {
        height: 100%;
    }

    .slick-slide > div > div {
        height: 100%;
    }

    // .slick-slide .block-products-carousel__column {
    //     height: 100%;
    // }

    @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

    .block-slideshow__slide {
        height: 100%;
    }

    .slick-arrow {
        cursor: pointer;
        height: 30px;
        color: #f1861e;
    }

    .slick-arrow::before {
        content: unset;
    }

    .slick-prev {
        top: 50%;
        left: -40px !important;
        z-index: 10;
    }
    .slick-prev::before {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f053";
        color: #f1861e;
        font-size: 30px;
    }

    .slick-next {
        top: 50%;
        right: -40px;
        z-index: 10;
    }

    .slick-next::before {
        font: normal normal normal 14px/1 FontAwesome;
        content: "\f054";
        color: #f1861e;
        font-size: 30px;
    }
}

.home_favorite_ads_list.deneme_title {
    background: orange;
}


.home_page_how_it_works {
    .card {
        background: #f5f5f5;
    }
}
