
@import '../variables';
@import '../functions';
@import '../mixins/direction';

.ripple-button {
    border-radius: 4px;
    border: none;
    // margin: 8px;
    // padding: 14px 24px;
    background: white;
    color: #fff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .ripple-button > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #F1861d;
    display: block;
    content: "";
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ripple-effect;
  }

  @keyframes ripple-effect {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(10);
      opacity: 0.375;
    }
    100% {
      transform: scale(35);
      opacity: 0;
    }
  }

  .ripple-button > .content {
    position: relative;
    z-index: 2;
  }
