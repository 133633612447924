@import "../variables";

.shop_page_payment {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "form card";

    .shop_page_payment_form {
        grid-area: form;
        max-height: 300px;

        .shop_page_payment_form_items{
            display:flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .shop_page_payment_card {
        grid-area: card;
        max-height: 300px;
    }
}


.input_form{
    background: #f5f5f5;
}
