/*
// .widget-comments
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';


.widget-comments {
    @include card();

    padding: 28px;

    a {
        color: inherit;
        transition: color .2s;
    }
    a:hover {
        color: $link-hover-color;
    }

    @media (max-width: 479px) {
        padding: 20px;
    }
}
.widget-comments__list {
    list-style: none;
    margin: 0;
    padding: 8px 0 4px;
}
.widget-comments__item {

    & + & {
        margin-top: 26px;
    }
}
.widget-comments__author {
    font-size: 15px;
    line-height: 18px;

    a {
        border-bottom: 2px solid $card-divider-color;
        transition: border .2s;
    }
    a:hover {
        border-color: rgba($link-hover-color, .8);
    }
}
.widget-comments__content {
    margin-top: 12px;
    font-size: 15px;
    line-height: 20px;
}
.widget-comments__meta {
    margin-top: 4px;
    font-size: 13px;
    color: $card-font-muted-color;
    display: flex;
    white-space: nowrap;
}
.widget-comments__date {
    flex-shrink: 0;
}
.widget-comments__name {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;

    @include direction {
        #{$padding-inline-start}: 20px;
    }

    &::before {
        position: absolute;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background: currentColor;
        border-radius: 2px;
        top: 8px;

        @include direction {
            #{$inset-inline-start}: 8px;
        }
    }
}
