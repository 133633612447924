.bizleal_product_detail_like {
    display: flex;
    justify-content: flex-end;
    .btn-like {
        border: unset;
        background: unset;
    }
    .btn-like:focus {
        outline: none;
    }
}
.countdown_container {
    justify-content: flex-end;
    display: flex;
    justify-content: space-between;
}
.countdown-item {
    flex: 1 1 0px;
    margin-top: 0px;
}
.bizleal_product_detail_title_first {
    line-height: 17px;
    color: #0a7081;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}
.bizleal_product_detail_title_second {
    line-height: 12px;
    color: #9b9b9b;
    font-family: "Helvetica", sans-serif;
    font-size: 12px;
    margin-top: 5px;
}
.bizleal_product_detail_progressbar {

    .item_progress {
        @media (max-width: 768px) {
            max-width: 999999px;
        }

        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 12px;
        margin-top: 35px;
        margin-bottom: 35px;

        border-radius: 4px;
        // background: gray; //linear-gradient(to right, red, orange , yellow, green); /* Standard syntax (must be last) */
        position: relative;

        &:before {
            content: " ";
            margin-left: 5px;
            margin-right: 5px;
            width: calc(100% - 10px);
            height: 100%;
            border-radius: 4px;
            background: gray;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .progress_tip.reverse {
        top: calc(100% + 6px);
        bottom: unset;

        .tip_title {
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-bottom: 0;
        }

        .tip_title:before {
            position: absolute;
            top: -5px;
            bottom: unset;
            width: 0;
            height: 0;
            border: unset;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;

            border-bottom: 5px solid #f1861e;
            content: " ";
        }
    }

    .progress_tip {
        font-size: 10pt;
        position: absolute;
        bottom: calc(100% + 5px);
        left: calc(100% * var(--tip-left) - 10px);
        // animation: progress_blink 0.5s 0.5s linear alternate infinite;
        transition: left 1.5s ease-out, transform 1.5s ease-out;
        transform: translateX(calc((var(--tip-left) * -100%) + 10px));

        .tip_title {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            white-space: nowrap;
            overflow: hidden;

            pointer-events: none;
            opacity: 1;
            // background: #f1861e;
            //               animation: color-mix(var(--tip-left));
            padding: 4px;
            color: white;
            font-weight: bold;
        }

        .tip_title:before {
            left: calc(var(--tip-left) * 100% + ((0.5 - var(--tip-left)) * 10px));
            transition: left 1.5s ease-out;
            position: absolute;
            transform: translateX(-50%);
            bottom: -5px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;

            border-top: 5px solid #f1861e;
            content: " ";
        }
    }

    .item_progress_bar {
        margin-left: 5px;
        width: calc(100% - 10px);

        //  * var(--tip-left)
        &:after {
            position: absolute;
            background: gray;
            height: 100%;
            right: 0px;
            width: calc(100% * (1 - var(--tip-left)));
            transition: width 1.5s ease-out;

            content: " ";
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        height: 100%;
        border-radius: 4px;
        //background: gray;
        // animation: kf-color-mix 5s linear alternate infinite;

        // animation:  kf-color-mix 1s  linear forwards paused;
        // background: linear-gradient(to right, red, orange , yellow, green); /* Standard syntax (must be last) */
        background-image: linear-gradient(to right, red, orange, yellow, green);
        /* clean-css ignore:start */
        background-repeat: no-repeat;
        // animation-name: kf-color-mix;
        // animation-duration: 1s;
        // animation-delay: calc(-0.999s * var(--tip-left));
        // animation-direction: normal;
        // animation-timing-function: linear;
        // animation-play-state: paused;

        /* clean-css ignore:end */

        position: absolute;
        top: 0;
        left: 0;
    }
}
