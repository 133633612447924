/*
// .addresses-list
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

@mixin local-layout($columns, $gutter) {
    .addresses-list__item {
        max-width: calc((100% - #{($columns - 1) * $gutter}) / #{$columns});
    }
    /*.addresses-list__divider:nth-child(2n) {
        width: $gutter;
    }
    .addresses-list__divider:nth-child(#{$columns * 2}n) {
        width: 100%;
        height: $gutter;
    }*/
}

.address_list_grid {
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    .address_list_items {
        width: 100%;
        border: 2px solid #f5f5f5;
        .item_plus_wrapper {
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
}
.new_address_wrapper {
    display: flex;
    justify-content: space-between;
    .address-list__plus {
        position: relative;
        margin-right: 10px;
        border-radius: 30px;
        transition: border-color 0.2s;
        padding: 6px 16px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 18px;
            height: 3px;
            background-color: #333;
            border-radius: 1.5px;
            top: calc(50% - 2px);
            transition: background-color 0.2s;

            // @include direction {
            //     #{$inset-inline-start}: calc(50% - 16px);
            // }
        }

        &::after {
            transform: rotateZ(90deg);
        }
    }
}
.new_address_wrapper:hover {
    cursor: pointer;
}

.item_plus_wrapper:hover {
    background: #f5f5f5;
    cursor: pointer;
}

.addresses-list__item {
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 16px;
    display: inline-block;
    white-space: break-spaces;
}
.addresses-list__item--new {
    border-radius: 50px;
    border: $card-border-width dashed #ebebeb;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    vertical-align: top;
    height: 100%;
}

.addresses-list__divider {
    display: inline-block;
    width: 24px;
}

.addresses-list__divider:last-child {
    display: none;
}
.plus_wrapper {
    border: 2px solid #f5f5f5;
    cursor: pointer;
    justify-content: center;
    transition: 0.3s;
    height: 100%;
}
.plus_wrapper:hover {
    background: #f5f5f5;
}
.shop_page_checkout_item_card_edit {
    .btn {
        height: auto;
        padding: 0;
    }
    .btn:focus {
        outline: none !important;
    }
}
.address-info {
    display: flex;
    justify-content: space-between;
}
.addresses-list__plus {
    width: 60px;
    height: 60px;
    border: 2px dashed #ebebeb;
    position: relative;
    // margin: auto;
    border-radius: 30px;
    margin-bottom: 24px;
    margin-top: 24px;
    transition: border-color 0.2s;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 4px;
        background-color: #e5e5e5;
        border-radius: 1.5px;
        top: calc(50% - 2px);
        transition: background-color 0.2s;

        @include direction {
            #{$inset-inline-start}: calc(50% - 16px);
        }
    }

    &::after {
        transform: rotateZ(90deg);
    }
}
.addresses-list__item--new:hover .addresses-list__plus {
    border-color: #e0e0e0;

    &::before,
    &::after {
        background-color: #dbdbdb;
    }
}

.form-address-right {
    float: right;
}

@media (min-width: breakpoint(xl-start)) {
    @include local-layout(3, 16px);
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(lg-end)) {
    @include local-layout(3, 12px);
}
@media (min-width: 480px) and (max-width: breakpoint(sm-end)) {
    @include local-layout(2, 12px);
}
@media (max-width: 479px) {
    @include local-layout(1, 12px);
}
