@import '../variables';
.product_home_grid_slider_link {
    display: block;
    width: 100%;
    height: 100%;
}


.product_home_grid_slider_cart {
    display: grid;
    height: 100%;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    min-height: 350px;
    background-color: #f5f5f5f5;
    padding: 12px;
    row-gap: 12px;

    .product_home_grid_slider_title {
        margin: 0;
        text-align: center;
        font-size: 11pt;
        color: #333333;
        font-weight: bold;
    }
    .product_home_card_image {
        flex-grow: 1000;

        width: 100%;
        // height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;

            height: 240px;
        }
    }

    .product-card__new-price {
        font-weight: bold;
        color: white;
        background: #f1861e;
        padding: 8px;
        border-radius: 4px;
    }

    .countdown-item {
        font-weight: bold;
        color: white;

        background: #f1861e;
        padding: 8px;
        border-radius: 4px;
        margin: 0;
        margin-right: 8px;
        span {
            color: white;
        }
    }
}

.product-card__old-price {
    font-weight: bold;
    color: #333333;
}

.product_home_grid_slider_title {
    margin: 0;
    text-align: center;
    font-size: 11pt;
    color: #333333;
    font-weight: bold;
}
.product_home_card_image {
    margin: 0;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;

        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        height: 200px;
    }
}
