/*
// .block-slideshow
*/
@import "../variables";
@import "../functions";
@import "../mixins/buttons";
@import "../mixins/direction";

$local-dots-height: 18px;
$local-dots-padding: 4px;
$local-dot-size: $local-dots-height - $local-dots-padding * 2;

.block-slideshow {
    margin-bottom: $block-margin-bottom;
    position: relative;

    .slick-dots {
        width: auto;
        bottom: 10px;
        position: absolute;
        // background: $block-slideshow-dots-bg;
        padding: $local-dots-padding;
        border-radius: $local-dots-height / 2;
        font-size: 0;
        list-style: none;
        border:1px solid $accent_color;
        margin: 0;
        right:50%;
        transform: translateX(-50% * -1);

        li {
            display: inline-block;
            height: 12px;
        }

        button {
            width: $local-dot-size;
            height: $local-dot-size;
            padding: 0;
            border-radius: $local-dot-size / 2;
            background: $block-slideshow-dot-default-color;

            &:focus {
                outline: none;
            }

            &:hover {
                background: $block-slideshow-dot-hover-color;
            }
            &::before{
                content: unset;
            }
        }
        .slick-active button {
            background: $block-slideshow-dot-active-color;
        }

        li + li {
            margin-left: 6px;
        }
    }
}
.block-slideshow__slide {
    position: relative;
    display: block;
    color: inherit;
    background: #f5f5f5;
    &:hover {
        color: inherit;
    }
}
.block-slideshow__slide-image {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}
.block-slideshow__slide-image--mobile {
    display: none;
}

.block-slideshow__slide-content {
    position: absolute;
    // top: 55px;

    // @include direction {
    //     #{$inset-inline-start}: 46px;
    // }
}
.block-slideshow__slide-title {
    font-size: 25px;
    line-height: 34px;
    font-weight: $font-weight-bold;
    margin-bottom: 12px;
    opacity: 0;
    transition: all 1s 0.2s;
    font-family: "Helvetica";
}
.block-slideshow__slide-text {
    line-height: 1.625;
    opacity: 0;
    transform: translateY(15px);
    transition: all 0.8s 0.5s;
    font-family: cursive;
}
.block-slideshow__slide-button {
    margin-top: 90px;
    opacity: 0;
    transition: all 1s 0.4s;
}
.block-slideshow .correct-slick-active {
    .block-slideshow__slide-title,
    .block-slideshow__slide-text,
    .block-slideshow__slide-button {
        opacity: 1;
        transform: none;
    }
}

.block-slideshow--layout--full {
    margin-top: 20px;
    //768'den sonrası
    @media (min-width: breakpoint(md-start)) {
        .block-slideshow__body,
        .block-slideshow__slide {
            min-height: 395px;
            height: 100%;
        }

        .block-slideshow__slide-content {
            top: 100px;
            left: 50%;
        }
        .block-slideshow__slide-title {
            margin-bottom: 16px;
            line-height: 36px;
        }
        .block-slideshow__slide-button {
            margin-top: 48px;
        }
    }
    //992-1199
    @media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
        .block-slideshow__slide-image--desktop {
            background-position: 0px top;
        }
        .block-slideshow__slide-content {
            left: 60%;
        }
    }
    //768-991
    @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
        .block-slideshow__slide-image--desktop {
            background-position: -20px top;
        }
        .block-slideshow__slide-content {
            bottom: 56px;
            left: 50%;
        }
        .block-slideshow__slide-title {
            margin-bottom: 8px;
        }
        .block-slideshow__slide-button {
            margin-top: 40px;
        }
    }
}

//767
@media (max-width: breakpoint(sm-end)) {
    .block-slideshow__body,
    .block-slideshow__slide {
        height: 395px;
    }
    .block-slideshow__slide-image--mobile {
        background-position: top center;
        display: block;
    }
    .block-slideshow__slide-content {
        top: 250px;
        text-align: center;
        left: 5%;
        right: 5%;
    }
    .block-slideshow__slide-title {
        font-size: 22px;
        line-height: 30px;
    }
    .block-slideshow__slide-text {
        display: none;
    }
    .block-slideshow__slide-button {
        margin-top: 24px;
    }
    .block-slideshow__slide-button .btn {
        @include btn-sm();
    }
}
