@import "../variables";
.product_new_ads_slider_link {
    display: inline-block;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    width: 95%;
    height: 100%;
    transition: box-shadow .1s ease-in;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2);
    }
}

.product_new_ads_slider_card {
    display: grid;
    height: 100%;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    min-height: 350px;
    row-gap: 12px;
    padding-bottom: 10px;

    .product-card__prices__home {
        perspective: 324px;
        position: relative;

        backface-visibility: hidden;
        .product3d_add_to_cart {
            backface-visibility: hidden;
            position: absolute;
            left: 50%;
            transition: transform 0.4s ease;
            white-space: nowrap;
            transform: translateX(-50%) rotateX(180deg);
        }

        span {
            backface-visibility: hidden;
            transition: transform 0.4s ease;
        }

        .btn svg {
            display: inline;
            vertical-align: text-top;
            transition: transform 0.4s ease;
            transform: translateX(-20px);
        }
        .btn {
            font-weight: bold;
            color: white;
            font-size: 16px;
            padding: 8px;

            height: auto;
        }
    }

    &:hover .product-card__prices__home {
        .product3d_add_to_cart {
            transform: translateX(-50%);
        }
        span {
            transform: rotateX(-180deg);
        }

        .btn svg {
            transform: initial;
        }
    }

    // .bizleal_product_detail_cart:hover {

    //     .product-card__prices__home {
    //         transform: rotateX(180deg);

    //     }

    //     .product3d_add_to_cart {
    //         transform: rotateX(0deg);
    //     }
    // }

    .product_new_ads_slider_title {
        margin: 0;
        text-align: center;
        font-size: 11pt;
        color: #333333;
        font-weight: bold;
    }
    .product_home_card_image {
        margin: 0;
        // flex-grow: 1000;
        height: 100%;
        // width: 100%;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        // // height: 100%;
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: center;
        // img {
        //     display: block;
        //     margin-left: auto;
        //     margin-right: auto;

        //     height: 240px;
        // }
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;

            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            height: 200px;
        }
    }

    .product-card__new-price {
        font-weight: bold;
        color: white;
        background: #f1861e;
        padding: 8px;
        border-radius: 4px;
    }

    .countdown-item {
        font-weight: bold;
        color: $accent_color;

        background: #f5f5f5;
        padding: 4px;
        border-radius: 4px;
        margin: 0;
        margin-right: 8px;
        span {
            color: $accent_color;
        }
    }
}

.product-card__prices__home {
    display: flex;
    place-items: center;
    align-items: center;
    justify-items: center;
    justify-self: center;
    column-gap: 10px;
}

.product-card__old-price {
    font-weight: bold;
    color: #333333;
}
