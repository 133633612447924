@keyframes progress_blink {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes kf-color-mix {
    0% {
        background-color: red;
    }
    33% {
        background-color: orange;
    }
    66% {
        background-color: yellow;
    }
    100% {
        background-color: green;
    }
}

.bizleal_product_list__container {
    &:after {
        content: " ";
        background-color: #f5f5f5;
        grid-area: line;
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr auto 1fr;

        grid-template-areas:
            "title  like countdown"
            "image image ."
            "image image share"
            "image image order"
            "info info info"
            "price price price"
            "instantPrice bizleal bizleal";

        &:after {
            content: none;
        }
        .bizleal_product_list__order {
            align-items: flex-end !important;
        }
    }

    @media (max-width: 479px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 10px;
        grid-template-areas:
            "title title title like"
            "countdown countdown countdown countdown"
            "image image image ."
            "image image image share"
            "image image image order"
            "info info info info"
            "price price price price"
            "instantPrice instantPrice bizleal bizleal";

        &:after {
            content: none;
        }
        .bizleal_product_list__countdown .countdown_container {
            justify-content: center;
        }


    }

    .countdown_container {
        justify-content: flex-end;
        display: flex;
    }

    background-color: #ffffff;
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    margin-bottom: 11px;
    overflow: hidden;
    position: relative;
    grid-row-gap: 13px;
    grid-column-gap: 12px;
    padding: 12px;
    display: grid;
    width: 100%;
    grid-template-columns: 2fr auto 1px 1fr 1fr;

    grid-template-areas:
        "title like  .    .    countdown"
        "image share     line info info"
        "image share line info info"
        "image share line price price"
        "image order line instantPrice bizleal";

    .bizleal_product_list__instant_price {
        grid-area: instantprice;

        section {
            float: right;
        }
    }

    .bizleal_product_list__countdown {
        grid-area: countdown;
    }
    .bizleal_product_list__title {
        align-items: flex-start;
        border-bottom-color: #efefef;
        grid-area: title;
        display:flow-root

        .bizleal_product_list__title--container {
            display: block;
            float: left;
        }
        .bizleal_product_list__title--first {
            line-height: 17px;
            color: #0a7081;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
        }
        .bizleal_product_list__title--second {
            line-height: 12px;
            color: #9b9b9b;
            font-family: "Helvetica", sans-serif;
            font-size: 12px;
            margin-top: 5px;
        }
        .bizleal_product_list__title--countdown {
            display: block;
            float: right;
            text-align: right;
            color: #f1861e;
            @media (max-width: 479px) {
                text-align: center;
                display: flex;
                justify-content: center;
                color: #f1861e;
            }
        }
    }
    .bizleal_product_list__image {
        grid-area: image;
        display: flex;
        min-height: 140px;
        position:relative;

        a {
            width: 100%;
            // height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .bizleal_product_list__instantPrice {
        grid-area: instantPrice;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .bizleal_product_list__instantPrice_text {
            font-size: 12px;
        }
    }
    // .bizleal_product_list__instantPrice.bizleal_product_list_instantPrice_text {
    //     font-size: 12px;
    // }

    .bizleal_product_list__share {
        align-self: center;
        grid-area: share;
        .social_buttons_alignment {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .bizleal_product_list__like {
        display: flex;
        justify-content: flex-end;
        .btn-like {
            border: unset;
            background: unset;
        }
        .btn-like:focus {
            outline: none;
        }
    }
    .bizleal_product_list__info {
        grid-area: info;
        align-self: center;
    }

    .bizleal_product_list__order {
        grid-area: order;
        display: flex;
        color: #f1861e;
        font-weight: bolder;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        line-height: 15px;
        padding-top: 5px;
        position: relative;
    }
    .bizleal_product_list__order span {
        color: #333;
        font-size: 11px;
        margin-top: 6px;
    }

    .bizleal_product_list__price-wrapper {
        grid-area: price;
    }
    .bizleal_product_list__price-wrapper .price-field__wrapper {
        clear: right;
        display: flex;
        flex-direction: column;
        grid-area: price;
        justify-self: stretch;
        align-self: self-end;

        @media (max-width: 479px) {
            float: none;
            width: 100%;
        }
    }
    .bizleal_product_list__price-wrapper .price-field__labels__wrapper {
        flex-direction: column;
        justify-content: center;
        grid-area: price;
        background-color: #f5f5f5;
        display: flex;
        row-gap: 8px;
        @media (max-width: 479px) {
            float: none;
            width: 100%;
        }
    }
    .bizleal_product_list__price-wrapper .price-field__labels__wrapper-up,
    .bizleal_product_list__price-wrapper .price-field__labels__wrapper-down {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__day-container {
        display: flex;
        align-items: center;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__day {
        color: #9ab2b3;
        font-family: "Helvetica", sans-serif;
        font-size: 13px;
        line-height: 17px;
        position: relative;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__day-container i {
        color: #008dd4;
        font-size: 20px;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__price {
        color: #d0021b;
        font-family: "Helvetica", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        position: relative;
        margin-right: 5px;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__price::before {
        background-color: #d0021b;
        content: "";
        height: 1px;
        position: absolute;
        top: 50%;
        width: 95%;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__daily-price {
        color: #006a7c;
        font-family: "Helvetica", sans-serif;
        font-size: 13px;
        line-height: 13px;
        text-align: end;
    }
    .bizleal_product_list__price-wrapper .price-field__labels__price2 {
        color: #4a4a4a;
        font-family: "Helvetica", sans-serif;
        font-size: 21px;
        font-weight: 700;
        line-height: 28px;
        position: relative;
    }
    .bizleal_product_list__price-wrapper.price_field_button__bizleal {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .bizleal_product_list__bizleal {
        grid-area: bizleal;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .bizleal_product_list__bizleal p {
        background: #f1861e;
        width: 50px;
        height: 30px;
        color: #fff;
    }

    .bizleal_product_list__bizleal.bizleal_space_area {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: white;
    }
}
/**Yeni CSS**/

.bizleal_product_list__title--first {
    line-height: 17px;
    color: #0a7081;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
}
.bizleal_product_list__title--second {
    line-height: 12px;
    color: #9b9b9b;
    font-family: "Helvetica", sans-serif;
    font-size: 12px;
    margin-top: 5px;
}

.bizleal_product_list__like {
    .btn-like {
        border: unset;
        background: unset;
    }
    .btn-like:focus {
        outline: none;
    }
}

.bizleal_product_list__image {
    grid-area: image;
    display: flex;
    min-height: 140px;

    a {
        width: 100%;
        // height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.countdown_container {
    justify-content: flex-end;
    display: flex;
}

.bizleal_product_list_container2{
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.13);
}

